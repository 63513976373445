
import AvatarImage from "@/components/AvatarImage.vue";
import UserStatusTemplate from "@/components/UserStatusTemplate.vue";
import Channel from "@/interfaces/Channel";
import User from "@/interfaces/User";
import { hideDMChannel } from "@/services/channelService";
import { ChannelsModule } from "@/store/modules/channels";
import { DrawersModule } from "@/store/modules/drawers";
import { MessageLogStatesModule } from "@/store/modules/messageLogStates";
import { MessagesModule } from "@/store/modules/messages";
import { NotificationsModule } from "@/store/modules/notifications";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "FriendTemplate",
  components: { AvatarImage, UserStatusTemplate },
  props: {
    friend: {
      type: Object as PropType<{ recipient: User; status: number }>,
      required: false,
    },
    dmChannel: {
      type: Object as PropType<Channel & { recipients: User[] }>,
      required: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    user(): any {
      if (this.friend) {
        return this.friend.recipient || this.friend;
      } else {
        return this.dmChannel?.recipients[0];
      }
    },
    isFriendSelected(): any {
      const channel = ChannelsModule.getDMChannel(
        this.$route.params.channel_id as string
      );
      if (!channel) return undefined;
      if (!channel.recipients) return undefined;
      return channel.recipients[0].id === this.user?.id;
    },
    notification(): any {
      if (!this.user) return false;
      return NotificationsModule.notificationByUserID(this.user.id);
    },
  },
  methods: {
    clickedEvent(event: any) {
      DrawersModule.SetLeftDrawer(false);
      if (
        !event.target.closest(".avatar") &&
        !event.target.closest(".close-button")
      ) {
        if (!this.user) return;
        ChannelsModule.LoadDmChannel(this.user.id);
      }
    },
    hide() {
      if (!this.dmChannel) return;
      if (this.isFriendSelected) {
        this.$router.push("/app/dms");
      }
      hideDMChannel(this.dmChannel.channelId).then(() => {
        if (!this.dmChannel) return;
        ChannelsModule.RemoveChannel(this.dmChannel.channelId);
        MessagesModule.DeleteChannelMessages(this.dmChannel.channelId);
        MessageLogStatesModule.RemoveState(this.dmChannel.channelId);
      });
    },
    showProfile() {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.user?.id },
      });
    },
  },
});
